<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card flat color="transparent">
          <!-- <h2>Error</h2> -->
          <v-img
            src="../../assets/img/logo_stadio.svg"
            alt="sitio-web-contruccion"
            width="100%"
            contain
          />
          <!-- <div class="text-center mb-5 my-5" style="font-size: 24px; font-weight-bold: 400">
            En este momento en mantenimiento.
            <br />
            Pero quedate cerca que se vienen cosas buenas.
          </div> -->
          <div class="text-center mb-5 my-5" style="font-size: 24px; font-weight-bold: 400">
            Error
          </div>
          <!-- <div class="d-flex">
            <a
              class="mx-auto"
              href="http://qr.afip.gob.ar/?qr=5n7kOdfOgUiazvIPvWJ9dg,,"
              target="_F960AFIPInfo"
              ><img
                width="90px"
                height="123px"
                src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                border="0"
                alt=""
            /></a>
          </div> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>
<style>
</style>
